import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import './404.scss'
import { connect } from 'react-redux'
import { mapStateToProps } from '../state/mapState'
import { mapDispatchToProps } from '../state/mapDispatch'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const MissingPage = props => {
  const { allContentfulPageNotFound } = useStaticQuery(graphql`
    query {
      allContentfulPageNotFound {
        edges {
          node {
            description
            backTo
            home
            background {
              fixed(height: 1080) {
                ...GatsbyContentfulFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  const common = allContentfulPageNotFound.edges[0].node
  const background = common.background.fixed
  const description = common.description
  const backTo = common.backTo
  const home = common.home

  return (
    <div className="application">
      <Helmet>
        <title>Alex Verzea's Site</title>
        <meta name="description" content="AV Site" />
        <link rel="canonical" href="https://av-site.com/404" />
      </Helmet>
      <Img
        className="background-cover"
        alt="404-background"
        fixed={background}
      />
      <h3 className="background-title first">{description}</h3>
      <h3 className="background-title second">
        {backTo} <Link to="/">{home}</Link>
      </h3>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissingPage)
